import React, { useState } from 'react';
import Chatbot from 'react-simple-chatbot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faMinus, faWindowMaximize } from '@fortawesome/free-solid-svg-icons';
import Sarasameroca from './Components/saras-logo.png';
//import ChatbotIcon from './Components/ChatbotMan.png';
//import useravatar1 from './Components/ChatbotAvatar.jpeg';
import logo2 from './Components/Logo_sarasAmerica.png'
import Logo from './Components/SarasAmerica_logo.png';
import sarasamerica from './Components/SarasAmerica_NewLogo.png';
import logo1 from './Components/SarasAmerica_NewLogo-removebg-preview.png'
//import C99 from './Components/saras-logo_ico.png';
import steps from './Components/ChatbotSteps';
import { ThemeProvider } from 'styled-components';
import './Components/App.css';
import chatbotuser from './Components/ChatBot_Background.jpeg';
import ImageChatbot from './Components/Imagefor_Bot.jpg';
import botAvathar from './Components/botavatar_sarasamerica.png';

function App() {
  const [showChatbot, setShowChatbot] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => {
    setShowChatbot(false);
    setShowOffcanvas(false);
  };

  const handleMinimize = () => {
    setShowChatbot(false);
    setShowOffcanvas(true);
  };

  const handleMaximize = () => {
    setShowChatbot(true);
    setShowOffcanvas(false);
  };

  const Header = () => (
    <div className="chatbot-header">
      <img src={logo1} alt="Chatbot Logo" className="chatbot-logo" title='Saras America'/>
      <div className="header-buttons">
        <div className="tooltip" onClick={handleMinimize} title='Minimize'>
          <FontAwesomeIcon icon={faMinus} className="minimize-icon" />         
        </div>
        <div className="tooltip" onClick={handleClose} title='Close'>
          <FontAwesomeIcon icon={faTimes} className="close-icon" />     
        </div>
      </div>
    </div>
  );  
  const theme = {
    botBubbleColor: 'rgb(228 243 248)',
    userBubbleColor: '#f4e2cc',
    userFontColor: 'black',
  } 
  return (
    <>
      {showChatbot && (
        <ThemeProvider theme={theme}>
        <div className="chatbot-container">
         
          <Chatbot            
            headerComponent={<Header />}
            userAvatar={ImageChatbot}
            botAvatar={logo2}          
            steps={steps}  
            style={{
             // height: 'auto',        
              background: `url(${chatbotuser})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
             backgroundPosition: 'center',
              backgroundAttachment: 'fixed',
              backgroundOpacity: '0.5',
            }}
            enableSmoothScroll autoScrollDown 
                 
          />       
        </div>
        </ThemeProvider>
      )}
      {showOffcanvas && (
        <div className="offcanvas-container">
          <div className="offcanvas-header">
            <img src={logo1} alt="Chatbot Logo" className="chatbot-logo_offcanvas" title='Saras America'/>
            <div className="header-buttons">
              <div className="tooltip" onClick={handleMaximize} title='Maximize'>
                <FontAwesomeIcon icon={faWindowMaximize} className="maximize-icon" />             
              </div>
              <div className="tooltip" onClick={handleClose} title='Close'>
                <FontAwesomeIcon icon={faTimes} className="close-icon" />               
              </div>
            </div>
          </div>        
        </div>
      )}
      {!showChatbot && !showOffcanvas && (
        <div className="chatbot-icon" onClick={() => setShowChatbot(true)}>
          <img src={ImageChatbot} alt="Chatbot Icon" />
        </div>
      )}
    </>
  );
}

export default App;
