 import axios from 'axios';
 import './ChattbotSteps.css';

const response = await fetch('/manifest.json');
const manifest = await response.json();

let generatedUserId;

const sendUserDataToServer = async () => {
    const name = localStorage.getItem("name") || '';
    const email = localStorage.getItem("email") || '';
    try {
        const userData = {
            Name: name,
            Email: email,
        };
        const response = await axios.post(manifest.apiEndpoints.Users, userData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        generatedUserId = response.data.user_Id;
    } catch (error) {
        console.error('Error sending user data:', error);
        console.error('Response data:', error.response);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        throw error;
    }
};
const getauthorizationOptions = async () => {
    try {
        const response = await axios.get(manifest.apiEndpoints.Authorizations);
        const cyberSecureOptions = response.data;

        return cyberSecureOptions.map(cyberSecurity => ({
            value: cyberSecurity.authorization_Id,
            label: cyberSecurity.authorization_Type,
            // trigger: ({ value }) => {
            //   localStorage.setItem("CyberServices", value);
            //   return getTriggerForCyberSecurity(value);
            // },
            trigger: ({ value }) => {
                const selectedService = { value, serviceId: cyberSecurity.authorization_Id };
                localStorage.setItem("selectedService", JSON.stringify(selectedService));
                return getTriggerForCyberSecurity(value);

                //   const selectedCyberservices = { value }; // Store directly as an object
                //   localStorage.setItem("authorizationwork", JSON.stringify(selectedCyberservices));
                //   return getTriggerForCyberSecurity(value);
            }
        }));
    } catch (error) {
        console.error('Error fetching authorization options:', error);
        return [];
    }
};

const getTriggerForCyberSecurity = (CyberServices) => {
    switch (CyberServices) {
        case 7:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
            return "knowyourdetails";
        case 1:
            return "fillyourformwithpr";

        default:
            return "endtrue";
    }
};
let fileContent;
let file;
const handleFileChange = (event) => {
     file = event.target.files[0];
    if (file) {
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop().toLowerCase();
        if (fileExtension !== 'docx' && fileExtension !== 'pdf' && fileExtension !== '.doc') {
            alert('Please upload a .docx or .pdf or .doc file.');
            event.target.value = ''; // Reset the file input
            return; // Exit if file extension is not supported
        }

        // Read the file content
        const reader = new FileReader();
        reader.onload = function (e) {
            fileContent = e.target.result;
            //handleChatEnd(fileContent, file);
            // Store the file content in localStorage
            // localStorage.setItem('resume', fileContent);

            //alert('Resume uploaded successfully!');
        };
        reader.readAsDataURL(file);
    }
};

const handleChatEnd = async (fileContent, file) => {
    const selectedAuthorization = JSON.parse(localStorage.getItem("selectedService")) || {};
    const serviceIdForBackend = Number(selectedAuthorization.serviceId) || 0;
    const selectedSkillsString = localStorage.getItem("skills") || '';
    const selectedSkills = selectedSkillsString ? JSON.parse(selectedSkillsString) : '';
    const selectedDate = localStorage.getItem("selectedDate") || '';
    // const selectedTime = localStorage.getItem("selectedTime"); 
  
    // Construct FormData object and append file and other data
    const formData = new FormData();
    formData.append('Resume', file); // Append file
    formData.append('Resume', fileContent); // Append file content
    formData.append('User_Id', generatedUserId);
    formData.append('Authorization_Type', serviceIdForBackend);
    formData.append('Skills', selectedSkills);
    formData.append('Meeting_Date', selectedDate);
    //   formData.append('Meeting_Time', selectedTime);
    try {
        const response = await axios.post(manifest.apiEndpoints.Configurations, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
       // console.log('User configuration data sent successfully', response);       
        localStorage.removeItem('selectedService');
        localStorage.removeItem('skills');
        localStorage.removeItem('selectedDate');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('Othes');
    } catch (error) {
        console.error('Error sending configuration data:', error);
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
    }
};

function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
}
const steps = [
    {
        id: "Greet",
        message: "Hi there! Welcome to Saras America Inc.",
        trigger: "yourname",
    },
    {
        id: "yourname",
        message: "May I know your full name?",
        trigger: "waitingforname"
    },
    {
        id: "waitingforname",
        user: true,
        validator: (value) => {
            if (/^[A-Za-z ]+$/.test(value)) {
                localStorage.setItem("name", value);
                return true;
            }
            return "Enter only alphabetic characters.";
        },
        trigger: "askemail",
    },
    {
        id: "askemail",
        message: "Hello {previousValue}, kindly share your email id for continued communication",
        trigger: "waitforemail",
    },
    {
        id: "waitforemail",
        user: true,
        validator: (value) => {
            if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                localStorage.setItem("email", value);
                sendUserDataToServer();
                return true;
            }
            return "Please enter a valid email.";
        },
        trigger: "Done",
    },
    {
        id: "Done",
        message: "Are you looking for any job openings in USA?",
        trigger: "waiting1",
    },
    {
        id: "waiting1",
        options: [
            {
                value: "Yes",
                label: "Yes",
                trigger: "workauthorization",
            },
            {
                value: "No",
                label: "No",
                trigger: "endtrue",
            },
            // {
            //     value: "Others",
            //     label: "Others",
            //     trigger: "othertriggerd",
            // },
        ]
    },
    // {
    //     id: "othertriggerd",
    //     message: "Please type in a brief description of what you're looking for",
    //     trigger: "discriptionofothers"
    // },
    // {
    //     id: "discriptionofothers",
    //     user:true,
    //     validator: (value) => {
    //         if (!value) {
    //          return "Please type what you're looking for";
    //         } 
    //         localStorage.setItem("Others", JSON.stringify(value));
    //         return true;
    //     },
    //     trigger: "typeotherresponse"
    // },
    // {
    //    id: "typeotherresponse",
    //    message: "thank you we will look into it.",
    //   // trigger:"blinkedmessage",
    //   trigger: () => {
    //     handleChatEnd(fileContent,file);
    //     return "blinkedmessage";
    //    }
       
    // },
    {
        id: "workauthorization",
        message: "What is your current work authorization?",
        trigger: "multiplevisas",
    },
    {
        id: "multiplevisas",
        options: await getauthorizationOptions()
    },
    {
        id: "fillyourformwithpr",
        message: "Kindly fill below details",
        trigger: "timefillingform"
    },
    {
        id: "timefillingform",
        component: (
            <div style={{ fontSize: '11px' }} className="container">
                <div className="form-group">
                    <label><strong style={{color: '#020250'}}>Pick Your Best Date&Time:</strong></label>&nbsp;
                    <input
                        type="datetime-local"
                        style={{ fontSize: '11px'}}
                        className="form-control"                      
                        id="selectedDate" 
                        min={getCurrentDateTime()}                                                                       
                    />
                </div>
                <p style={{ fontSize: '12px', color: '#020250',textAlign:'center',fontWeight: '700' }} className="text-muted text-center">Select the Date&Time and click the arrow button below to proceed</p>
            </div>
        ),
          trigger: "availabilitytime", 
    },
    {
        id: "availabilitytime",
        user: true,
        // trigger: () => {
        //     const selectedDate = document.getElementById('selectedDate').value;
        //     const selectedTime = document.getElementById('selectedTime').value;
        //     localStorage.setItem('selectedDate', selectedDate);
        //     localStorage.setItem('selectedTime', selectedTime);
        //     handleChatEnd();
        //     return "sharinginfo";
        // },       
        validator: (value) => {
            const selectedDate = document.getElementById('selectedDate').value;
    
            if (!selectedDate) {
                // Display a validation message if no date is selected
                return 'Please select a date before proceeding.';
            }
            // If validation passes, return true
            return true;
        },       
        trigger: () => {
            const selectedDate = document.getElementById('selectedDate').value;            
           // const selectedDateTime = new Date(selectedDate);
        
            // Convert the selected date to Eastern Standard Time (EST)
           // const estFormat = selectedDateTime.toLocaleString('en-US', { timeZone: 'America/New_York' });
        
            // console.log("Eastern Standard Time Format (EST):", estFormat);
        
            localStorage.setItem('selectedDate', selectedDate);
            handleChatEnd();
            return "selectedDate";
        },
        

      // trigger: "selectedDate"
    },
  
    {
        id: "selectedDate",
        message: "Thanks for connecting with Saras America, we are excited, our team will be reaching out to you shortly.",
        trigger: "blinkedmessage"
    },
    {
        id: "knowyourdetails",
        message: "Kindly Brief about your top primary skills/Technology?",
        trigger: "typeuserskills"
    },
    {
        id: "typeuserskills",
        user: true,       
        validator: (value) => {
            if (!value) {
             return 'Please enter your top skills/technology.';
            } 
            localStorage.setItem("skills", JSON.stringify(value));
            return true;
        },
        // validator: (value) => {
        //     if (!value) {
        //         return 'Please enter your top primary skills/technology.';
        //     } else {
        //         localStorage.setItem("skills", JSON.stringify(value));
        //         return true;
        //     }
        // },
        trigger: "opentorelocate"
    },
    {
        id: "opentorelocate",
        message: "Open to relocate?",
        trigger: "opentoyesno",
    },
    {
        id: "opentoyesno",
        options: [
            {
                value: "Yes",
                label: "Yes",
                trigger: "fillyourform"
            },
            {
                value: "No",
                label: "No",
                trigger: "fillyourform"
            }
        ]
    },
    {
        id: "fillyourform",
        message: "Submit your recent CV Our team will reach you out ASAP.",
        trigger: "formfilling"
    },
    {
        id: "formfilling",
        component: (
            <div style={{ fontSize: '11px' }} className="container">
                <div className="form-group">
                    <label><strong style={{color: '#020250'}}>Upload Resume:</strong></label>
                    <input type="file"
                        id="resumeFile"
                        // style={{ fontSize: '11px' }}
                        className="form-control-file custom-file-input"
                        accept=".doc,.docx,.pdf"
                        onChange={(event) => handleFileChange(event)}
                        style={{
                            fontSize: '11px',                           
                        }}
                    // onChange={handleFileChange}
                    />
                </div>
                <p style={{ fontSize: '12px', color: '#020250',textAlign:'center',fontWeight: '700'}} className="text-muted text-center">Upload resume and click the arrow button below to proceed</p>
            </div>
        ),
        trigger: "validdetails"
    },
    {
        id: "validdetails",
        user: true,
        validator: (value) => {
            // Perform file upload validation here
            const fileInput = document.getElementById('resumeFile');
            const file = fileInput.files[0]; // Get the selected file
    
            if (!file) {
                return 'Please upload a resume before proceeding.';
            }
    
            // Example: Check file size
            if (file.size > 5242880) { // 5 MB in bytes
                return 'Please upload a file smaller than 5 MB.';
            }
    
            // Example: Check file type
            const allowedTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];
            if (!allowedTypes.includes(file.type)) {
                return 'Please upload a .doc, .docx, or .pdf file.';
            }
    
            // Validation passed
            return true;
        },
        //trigger: "thankyoufor"
       trigger: () => {
        handleChatEnd(fileContent,file);
        return "thankyoufor";
       }
    },
    {
        id: "thankyoufor",
        message: "Thanks for connecting with Saras America, we are excited, our team will be reaching out to you shortly.",
        trigger:"blinkedmessage"
       // end: true
    },
    {
        id:"blinkedmessage",
        component: (
            <div className='blinkedbackground' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',/*backgroundColor: '#dfdcdc'*/ }}><p className="red-heart">&#x1F4E7;</p>
            <div className="animated-message" style={{ textAlign: 'center' }}>      
                <p style={{fontSize:'13px',}}><strong><mark style={{ backgroundColor: 'rgb(8 ,114, 179)', color: 'white',fontSize: '12.5px'}}>You will be receiving a complimentary report on top 10 technologies in the job market to your email.</mark></strong></p>
            </div><p className="red-heart">&#x2714;</p>
        </div>
        ),
        end: true
    },
    {
        id: "uploadresumeandgetbackmsg",
        message: "Drop your recent CV and Specify your location.Will connect with you.",
        trigger: "fillingresumeform",
    },
    {
        id: "fillingresumeform",
        component: (
            <div style={{ fontSize: '11px' }} className="container">

                <div className="form-group">
                    <label>preferred Location:</label>&nbsp;
                    <input type="text" style={{ fontSize: '11px' }} className="form-control" />
                </div>
                <div className="form-group">
                    <label>Upload Resume:</label>&nbsp;
                    <input type="file" style={{ fontSize: '11px' }} className="form-control-file" />
                </div>
                <p style={{ fontSize: '11px', color: '#fff' ,fontWeight: '500'}} className="text-muted text-center">Select the options and click the arrow button below to proceed</p>
            </div>
        ),
        trigger: "thankuyoumessage"
    },
    {
        id: "thankuyoumessage",
        user: true,      
         trigger: "endtrue"
    },
    {
        id: "endtrue",
        message: "Thank you for reaching Saras America team.",
        end: true,
    },
];

export default steps;
